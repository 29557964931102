<template>
	<layouts-minimum>
		<div class="py-10">
			<div class="pb-5">
				<h3 class="ls-tight fw-bolder h3">Mis direcciones</h3>
				<p class="text-sm text-muted">El listado muestra las direcciones cargadas por vos.</p>
			</div>
			<div v-if="loading" class="list-group list-group-flush">
				<user-location-cardskeletor v-for="i in 2" v-bind:key="i" class="list-group-item px-0" />
			</div>
			<div v-if="!loading && (hasLocation || addresses.length > 0)" class="list-group list-group-flush">
				<user-location-selected v-if="hasLocation" />
				<user-location-card v-for="address in addresses" v-bind:key="address.id" :address="address" class="list-group-item px-0" @reload="reload" />
			</div>
			<div v-if="!loading && (!hasLocation && addresses.length == 0)">
				<common-message-error text="No se encontraron direcciones cargadas." />
			</div>
			<div class="mt-5">
				<router-link :to="{name: 'location.create'}" class="btn btn-neutral w-100">Ingresar nueva dirección</router-link>
			</div>
		</div>
	</layouts-minimum>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import LocationRepository from '@/repositories/LocationRepository';
	import composableForm from '@/composables/form';
	import _ from 'lodash';

	export default {
		setup() {
			const { catchError } = composableForm();
			const hasLocation = computed(() => store.getters['location/isDefined']);
			const location = computed(() => store.getters['location/address']);
			const loading = ref(true);
			const addresses = ref([]);

			const getAddresses = async () => {
				LocationRepository.list().then((response) => {
					loading.value = false;
					var data = response.data;

					if(data.length > 0) {
						if(hasLocation.value && location.value.id) data = _.filter(data, function(o) { return o.address_id != location.value.id; });
						addresses.value = data;
					}
				}).catch((e) => {
					loading.value = false;
					catchError(e);
				});
			}

			const reload = () => {
				addresses.value = null;
				getAddresses();
			}

			onMounted(getAddresses);

			return { loading, addresses, reload, hasLocation };
		}
	}
</script>